import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import QuoteSection from "../components/QuoteSection";
import FormInput from "../components/FormInput";

import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import "./SuccessPage.css";
import NavbarContextProvider from "../utils/NavbarContextProvider";

export default function SuccessPage() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState(""); // Change this line
  const [lastName, setLastName] = useState("");
  const [memoFieldValue, setMemoFieldValue] = useState('');
  return (
    <NavbarContextProvider>
      <div className="donatePage">
        <Navbar />

        <section className="succes-cont">
          <h1>Thank you for</h1>
          <h1>bringing dignified relief</h1>
          <h1>to a local family.</h1>

          <h2>Your donation has been submitted.</h2>

          <h3>You will receive a confirmation email shortly. </h3>
        </section>
      <div className="address-form">
        <h1 className="donation-info-title">Address</h1>
        <FormInput
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            placeholder="Address"
          ></FormInput>

          <FormInput
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            placeholder="Apt/Unit"
          ></FormInput>

          <FormInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="City"
          ></FormInput>

          <FormInput
            type="text"
            value={memoFieldValue}
            onChange={(e) => setMemoFieldValue(e.target.value)}
            required
            placeholder="State"
          ></FormInput>

          <FormInput
            type="text"
            value={memoFieldValue}
            onChange={(e) => setMemoFieldValue(e.target.value)}
            required
            placeholder="Zip"
          ></FormInput>
           <FormInput
            type="text"
            value={memoFieldValue}
            onChange={(e) => setMemoFieldValue(e.target.value)}
            required
            placeholder="Phone"
          ></FormInput>
           <h1 className="donation-info-title">Dedication</h1>
           <FormInput
            type="text"
            value={memoFieldValue}
            onChange={(e) => setMemoFieldValue(e.target.value)}
            required
            placeholder="Dedication Message"
          ></FormInput>
          {/* <select
            value={memoFieldValue}
            onChange={(e) => setMemoFieldValue(e.target.value)}
            required
          >
            <option value="">Select a dedication type</option>
            <option value="in_honor_of">In honor of</option>
            <option value="in_dedication_of">In dedication of</option>
          </select> */}
          <button className="donate-button" onClick={null}>
            Submit
          </button>
        </div>  


        <QuoteSection
          backgroundColor={"rgba(255, 157, 137, 0.45)"}
          textColor={"#131f67"}
        >
          “I once learned that one isn't supposed to give specific praise about
          Hashem since once you start you can't stop. If you stop, "is that it?"
          On the same note, I feel like even if I sent you a thank you email
          daily that would be insufficient. "Is that it?" Just once a day? It's
          hard to describe how helpful your monthly and yom tov help is. We
          appreciate it so much. You're doing such great work and are helping
          more than you know. THANK YOU!!!”
        </QuoteSection>

        <section
          id={"contact"}
          style={{
            maxWidth: "1900px",
            margin: "0 auto",
            width: "75%",
          }}
        >
          <Contact />
        </section>

        <Footer />
      </div>
    </NavbarContextProvider>
  );
}
